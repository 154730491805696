
import {defineComponent} from 'vue';
import AtomMaterialIcon from '@/components/icon/AtomMaterialIcon.vue';

export default defineComponent({
  name: 'ContextMenuList',
  components: {AtomMaterialIcon},
  props: {
    items: {
      type: [Array, String],
      default: () => {
        return [];
      },
    },
  },
  setup(props, {emit}) {
    const onClick = (item: ContextMenuItem) => {
      if (!item.action) return;
      item.action();
      emit('hide');
    };

    return {
      onClick,
    };
  },
});
