
import {computed, defineComponent} from 'vue';
import {getOptionDefinition} from '@/utils/codemirror';

export default defineComponent({
  name: 'FileEditorSettingsFormItem',
  props: {
    value: {
      type: Object,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup(props, {emit}) {
    const def = computed<FileEditorOptionDefinition | undefined>(() => {
      const {name} = props;
      return getOptionDefinition(name);
    });
    const type = computed<FileEditorOptionDefinitionType | undefined>(() => def.value?.type);
    const data = computed<any>(() => def.value?.data);

    const onChange = (value: any) => {
      emit('input', value);
    };

    return {
      type,
      data,
      onChange,
    };
  },
});
