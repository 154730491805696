import { render } from "./FileEditorSettingsDialog.vue?vue&type=template&id=46e2cbcf&scoped=true"
import script from "./FileEditorSettingsDialog.vue?vue&type=script&lang=ts"
export * from "./FileEditorSettingsDialog.vue?vue&type=script&lang=ts"

import "./FileEditorSettingsDialog.vue?vue&type=style&index=0&id=46e2cbcf&lang=scss&scoped=true"
import "./FileEditorSettingsDialog.vue?vue&type=style&index=1&id=46e2cbcf&scoped=true&lang=css"
import "./FileEditorSettingsDialog.vue?vue&type=style&index=2&id=46e2cbcf&lang=css"
script.render = render
script.__scopeId = "data-v-46e2cbcf"

export default script