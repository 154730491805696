
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import DraggableList from '@/components/drag/DraggableList.vue';
import AtomMaterialIcon from '@/components/icon/AtomMaterialIcon.vue';
import FileEditorNavTabsContextMenu from '@/components/file/FileEditorNavTabsContextMenu.vue';

export default defineComponent({
  name: 'FileEditorNavTabs',
  components: {FileEditorNavTabsContextMenu, AtomMaterialIcon, DraggableList},
  props: {
    activeTab: {
      type: Object,
      required: false,
    },
    tabs: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    style: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  emits: [
    'tab-click',
    'tab-close',
    'tab-close-others',
    'tab-close-all',
    'tab-dragend',
    'show-more',
  ],
  setup(props, {emit}) {
    const activeContextMenuItem = ref<FileNavItem>();

    const navTabs = ref<HTMLDivElement>();

    const navTabsWidth = ref<number>();

    const navTabsOverflowWidth = ref<number>();

    const showMoreVisible = computed<boolean>(() => {
      if (navTabsWidth.value === undefined || navTabsOverflowWidth.value === undefined) return false;
      return navTabsOverflowWidth.value > navTabsWidth.value;
    });

    const contextMenuClicking = ref<boolean>(false);

    const tabs = computed<FileNavItem[]>(() => {
      const {tabs} = props as FileEditorNavTabsProps;
      return tabs;
    });

    const getTitle = (item: FileNavItem) => {
      return item.name;
    };

    const onClick = (item: FileNavItem) => {
      emit('tab-click', item);
    };

    const onClose = (item: FileNavItem) => {
      emit('tab-close', item);
    };

    const onCloseOthers = (item: FileNavItem) => {
      emit('tab-close-others', item);
    };

    const onCloseAll = () => {
      emit('tab-close-all');
    };

    const onDragEnd = (items: FileNavItem[]) => {
      emit('tab-dragend', items);
    };

    const onContextMenuShow = (item: FileNavItem) => {
      contextMenuClicking.value = true;
      activeContextMenuItem.value = item;

      setTimeout(() => {
        contextMenuClicking.value = false;
      }, 500);
    };

    const onContextMenuHide = () => {
      activeContextMenuItem.value = undefined;
    };

    const isShowContextMenu = (item: FileNavItem) => {
      return activeContextMenuItem.value?.path === item.path;
    };

    const updateWidths = () => {
      if (!navTabs.value) return;

      // width
      navTabsWidth.value = Number(getComputedStyle(navTabs.value).width.replace('px', ''));

      // overflow width
      const el = navTabs.value.querySelector('.draggable-list');
      if (el) {
        navTabsOverflowWidth.value = Number(getComputedStyle(el).width.replace('px', ''));
      }
    };

    watch(tabs.value, () => {
      setTimeout(updateWidths, 100);
    });

    onMounted(() => {
      // update tabs widths
      updateWidths();
    });

    return {
      activeContextMenuItem,
      navTabs,
      navTabsWidth,
      navTabsOverflowWidth,
      showMoreVisible,
      contextMenuClicking,
      getTitle,
      onClick,
      onClose,
      onCloseOthers,
      onCloseAll,
      onDragEnd,
      onContextMenuShow,
      onContextMenuHide,
      isShowContextMenu,
    };
  },
});
