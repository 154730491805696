
import {computed, defineComponent, onBeforeMount, readonly, ref} from 'vue';
import {useStore} from 'vuex';
import {plainClone} from '@/utils/object';
import variables from '@/styles/variables.scss';
import {getOptionDefinition, getThemes} from '@/utils/codemirror';
import FileEditorSettingsFormItem from '@/components/file/FileEditorSettingsFormItem.vue';
import {onBeforeRouteLeave} from 'vue-router';

export default defineComponent({
  name: 'FileEditorSettingsDialog',
  components: {FileEditorSettingsFormItem},
  setup() {
    const storeNamespace = 'file';
    const store = useStore();
    const {file} = store.state as RootStoreState;

    const options = ref<FileEditorConfiguration>({});

    const tabs = readonly([
      {name: 'general', title: 'General'},
      {name: 'edit', title: 'Edit'},
      {name: 'indentation', title: 'Indentation'},
      {name: 'cursor', title: 'Cursor'},
    ]);

    const optionNames = readonly({
      general: [
        'theme',
        'keyMap',
        'lineWrapping',
        'lineNumbers',
        'maxHighlightLength',
        'spellcheck',
        'autocorrect',
        'autocapitalize',
      ],
      edit: [
        'lineWiseCopyCut',
        'pasteLinesPerSelection',
        'undoDepth',
      ],
      indentation: [
        'indentUnit',
        'smartIndent',
        'tabSize',
        'indentWithTabs',
        'electricChars',
      ],
      cursor: [
        'showCursorWhenSelecting',
        'cursorBlinkRate',
        'cursorScrollMargin',
        'cursorHeight',
      ],
    });

    const activeTabName = ref<string>(tabs[0].name);

    const visible = computed<boolean>(() => {
      const {editorSettingsDialogVisible} = file;
      return editorSettingsDialogVisible;
    });

    const themes = computed<string[]>(() => {
      return getThemes();
    });

    const resetOptions = () => {
      const {editorOptions} = file;
      options.value = plainClone(editorOptions);
    };

    const onClose = () => {
      store.commit(`${storeNamespace}/setEditorSettingsDialogVisible`, false);
      resetOptions();
    };

    const onConfirm = () => {
      store.commit(`${storeNamespace}/setEditorOptions`, options.value);
      store.commit(`${storeNamespace}/setEditorSettingsDialogVisible`, false);
      resetOptions();
    };

    const onTabChange = (tabName: string) => {
      activeTabName.value = tabName;
    };

    const getDefinitionDescription = (name: string) => {
      return getOptionDefinition(name)?.description;
    };

    const getDefinitionTitle = (name: string) => {
      return getOptionDefinition(name)?.title;
    };

    onBeforeMount(() => {
      resetOptions();
    });

    onBeforeRouteLeave(() => {
      store.commit(`${storeNamespace}/setEditorSettingsDialogVisible`, false);
    });

    return {
      variables,
      options,
      activeTabName,
      tabs,
      optionNames,
      visible,
      themes,
      onClose,
      onConfirm,
      onTabChange,
      getDefinitionDescription,
      getDefinitionTitle,
    };
  },
});
