
import {defineComponent} from 'vue';
import {ClickOutside} from 'element-plus/lib/directives';

export const contextMenuDefaultProps = {
  visible: {
    type: Boolean,
    default: false,
  },
  placement: {
    type: String,
    default: 'right-start',
  },
  clicking: {
    type: Boolean,
    default: false,
  }
};

export const contextMenuDefaultEmits = [
  'hide',
];

export default defineComponent({
  name: 'ContextMenu',
  directives: {
    ClickOutside,
  },
  emits: contextMenuDefaultEmits,
  props: contextMenuDefaultProps,
  setup(props, {emit}) {
    const onClickOutside = () => {
      if (props.clicking) return;
      emit('hide');
    };

    return {
      onClickOutside,
    };
  },
});
